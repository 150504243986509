<template>
  <b-dropdown            
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      right
      variant="link" 
      text="Form"      
      no-caret

      id="myCart"

    >
    <template #button-content>
      <feather-icon        
        class="text-body"
        icon="ShoppingCartIcon"
        size="15"
        
      />
      <b-badge variant="primary" class="badge badge-up badge-pill  mr-1" style="margin-top:.6rem;"> {{cart.length}}</b-badge>
    </template>
   
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          My Cart
        </h4>
        <b-badge pill variant="light-primary">
           {{cart.length}} Items
        </b-badge>
      </div>
    </li>
    
      <b-dropdown-form>
        <b-dropdown-divider v-if="cart.length"/>
        <b-list-group flush>          
          <b-list-group-item v-for="item in cart" :key="item.uuid" >
            <div class="float-right">
              <feather-icon icon="XIcon" class="ml-1 cart-item-remove cursor-pointer text-danger" @click.stop="deleteItem(item)" />
            </div>
            <div class="d-flex">
              <b-img
                :src="imgUrl + item.coverImage"            
                :alt="item.name"
                @error="imgAlternativo"
                rounded
                width="70px"
                height="70px"
                class="mr-1"
              />
              <div>
                <p class="mb-1 text-mutted"> {{ item.name }}</p> 
                <b-badge variant="warning"> {{item.categoryName}} </b-badge>
              </div>            
              <div class="ml-1">
                <b-form-spinbutton v-model="item.qty" min="0" size="sm" @change="changeQty($event, item)"  
            :readonly="item.categoryName == 'Transfer'"/> <br>
                <strong class="mt-2">${{subTotalProductoQty(item)}}</strong>       
                         
              </div>
            </div>           
          </b-list-group-item>
        </b-list-group>
      <b-dropdown-divider v-if="cart.length"/>        
      </b-dropdown-form>
      
      <li v-if="cart.length" class="dropdown-menu-footer p-1" >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          ${{ total }}
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'order-summary' }"
      >
        Checkout
      </b-button>
    </li>

    <p v-if="!cart.length" class="m-0 p-1 m-1 text-center" > Your cart is empty </p>
  </b-dropdown>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [ market  ],
  components: {
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {    
      items: [],
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER, 
      imdDefault: require('@/assets/images/default.jpg'),
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapState('shop',['cart','breakdown']),
    ...mapGetters('shop',['totalDiscountOnAllProducts']),    
    subTotal(){                     
      return ((Math.round( (parseFloat(this.totalDiscountOnAllProducts) + parseFloat(this.breakdown.total)) * 100) / 100).toFixed(2) )
    },
    total(){           
      return ( (Math.round( (this.breakdown.total ) * 100) / 100).toFixed(2) )  
    }
  },
  methods: {
    ...mapMutations('shop',['removeProductInCart','calculateTotalBreakdown']),
    imgAlternativo( event ){
      event.target.src = this.imdDefault
    },
    subTotalProductoQty(item){      
      const subTotal = (Math.round( item.qty * ((item.discountApplied > 0 ) ? item.salepriceWithDiscount : item.saleprice)* 100) / 100).toFixed(2)      
      return subTotal
    },
    async changeQty(qty, item){
      if(qty == 0 ){
        this.removeProductInCart(item.uuid)             
      }
      if( qty > 0){
        this.calculateTotalBreakdown()
        await this.qtyHasChanged(item) // desde el mixim market
      }                       
    },
    deleteItem(item){
      this.removeProductInCart(item.uuid)             
    }, 
  },
}
</script>

<style scoped>
.b-dropdown-form {
    /* display: inline-block; */
    
    font-weight: 400;
    width: 450px;
}
.dropdown-toggle::after {
    content: none!important;;
    /* display: none!important; */
}
</style>

