<template>
     <div>
        <footer id="footer" class="footer">
            <div class="contenedor">
                <div class="nav-footer">
                    <h3>Mi viaje</h3>
                    <nav class="menu">
                        <a href="#">Empleo</a>
                        <a href="#">Prensa</a>
                        <a href="#">Políticas</a>
                        <a href="#">Ayuda</a>
                    </nav>
                </div>
                <div class="nav-footer">
                    <h3>Descubre-Mi viaje</h3>
                    <nav class="menu">
                        <a href="#">Confianza y seguridad</a>
                        <a href="#">Crédito de viajero</a>
                        <a href="#">AirB&B Citizen</a>
                        <a href="#">Viaje de negocio</a>
                    </nav>
                </div>
                <div class="nav-footer">
                    <h3>Hospedaje</h3>
                    <nav class="menu">
                        <a href="#">Razones para hospedar</a>
                        <a href="#">Hospitalidad</a>
                        <a href="#">Ser un anfitrión responsable</a>
                        <a href="#">Centro de la comunidad</a>
                    </nav>
                </div>
                <div class="nav-footer">
                    <nav class="sociales">
                        <ul>
                            <li>
                                <a href="http://facebook.com" target="_blank">
                                    <feather-icon icon="FacebookIcon"  />
                                </a>
                            </li>
                            <li>
                                <a href="http://twitter.com" target="_blank">
                                    <feather-icon icon="TwitterIcon"  />
                                </a>
                            </li>
                            <li>
                                <a href="http://instagram.com" target="_blank">
                                    <feather-icon icon="InstagramIcon"  />
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <nav class="menu">
                        <a href="#">Razones para hospedar</a>
                        <a href="#">Hospitalidad</a>
                        <a href="#">Ser un anfitrión responsable</a>
                        <a href="#">Centro de la comunidad</a>
                    </nav>
                </div>
            </div>
        </footer>
        <a href="#" class="btn-flotante activo" @click="toggleFooter">
            Show more
        </a>
    </div>
</template>

<script>
export default {
    methods:{
        toggleFooter(e){            
            e.preventDefault()
            const footer = document.querySelector('#footer')
            const btnFlotante = document.querySelector('.btn-flotante')
            if(footer.classList.contains('activo')){
                footer.classList.remove('activo')
                btnFlotante.classList.remove('activo')
                btnFlotante.innerText = 'Show more'

            }else{
                footer.classList.add('activo')
                btnFlotante.classList.add('activo')
                btnFlotante.innerText = 'X Close'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/landing.scss";
</style>