import { mapState, mapActions, mapMutations } from 'vuex'
import { toJson, stringAleatorio, showAlertMessage, currentDate} from '@/helpers/helpers'
import { version } from '../../../../package'

export const market = {
  data () {
    return { 
      params: this.$route.query,      
      codesAllowed: [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],      
    }
  }, 
  computed:{    
    ...mapState('products',['products','bestSellers']),    
    ...mapState('shop',['cart']),       
    ...mapState('auth',['queryParams','user', 'isLogged']),                                
  },
  methods: {  
    ...mapActions('auth',['loginWeb']),                         
    ...mapActions('shop', ['fetchBlackoutDatesProduct']),
    ...mapMutations('start',['setCurrencies']),
    ...mapMutations('auth',['setDataQueryParams', 'setUser', 'logout']),  
    ...mapMutations('products',['setProducts','setBestSellersProducts','setQtyProductDetail','setQtySingleProductDetail']),
    ...mapMutations('shop',['resetCartCustomerBreakdownOrder','setOperationDateProduct','setBlackoutsDateProduct']),
    clearStorage(){
      const localVersion = localStorage.getItem('appVersion')
      if(!localVersion){
        console.log('clearStorage, remover vuex', version)
        localStorage.removeItem('vuex') //clear vuex
        localStorage.setItem('appVersion', version)
        window.location.reload(true)
      }
      if( localVersion ){
        if( localVersion !== version ){
          console.log('no son versiones iguales')
          localStorage.removeItem('vuex') //clear vuex
          localStorage.setItem('appVersion', version)
          window.location.reload(true)
        }
      }
    }           ,
    resetState(){
      const thereAreProducts = this.products.length > 0
      const isEmptyParams = Object.keys(toJson( this.params )).length === 0
      // si parametros de la url no están vacíos y ya hay productos, reseteo
      if( !isEmptyParams && thereAreProducts){                  
        this.setDataQueryParams(null)              
        this.setProducts([])
        this.resetCartCustomerBreakdownOrder() 
        this.logout()          
      }
    },
    resetStateHome(){
      const thereAreProducts = this.bestSellers.length > 0
      const isEmptyParams = Object.keys(toJson( this.params )).length === 0
      // si parametros de la url no están vacíos y ya hay productos, reseteo
      if( !isEmptyParams && thereAreProducts){                  
        this.setDataQueryParams(null)              
        this.setBestSellersProducts([])
        this.resetCartCustomerBreakdownOrder() 
        this.logout()          
      }
    },
    setInitialParams(){   
      const paramsToUse =  Object.keys(this.params).length === 0 ? toJson(this.queryParams) : toJson(this.params)      
      const params = toJson(paramsToUse)       
      this.setDataQueryParams(params) 
    },
    async loginApp(){      
      const { ok, user } = await this.loginWeb( this.queryParams.token )     
      if(ok){
        this.setUser( user )
      }
      if(!ok){
        this.logout()
        this.setDataQueryParams(null)      
        this.$router.push({name: 'not-authorized' })
      }
    },
    generateProductsToAddCart(product){  
      const newDetail = product.detail.filter(detail => detail.qty > 0 )  //busco producto detalle al que se le modificó cantidades
      product.detail = newDetail 
      const items = this.createProductsToAddCart(product)
      if(!items.length){
        const text = `Choose an option from the variants, indicate a quantity`
        showAlertMessage( 'Choose an option', 'InfoIcon', text, 'warning', 3000, 'bottom-right' )
      }
      return items
    },
    createProductsToAddCart(product){
      const totalProducts = product.detail.length
      let items = []      
      for (let index = 0; index < totalProducts; index++) {
        items.push({...product, detailSelected: product.detail[index] }) //genero el arreglo de productos a agregar al cart     
      }
      items.forEach( item => {
        delete item.detail //borro array detail del item        
      })
      return items
    },
    formatProductBeforeAddingToCart(product){
      //DONDE REMUEVO Y AÑADO ELEMENTOS ANTES DE AÑADIR AL CART
      delete product.detail
      delete product.detailSelected.currency
      delete product.detailSelected.seccionventaid
      const { descmax, detaildisplay, id, saleprice, servicesihot, qty} = product.detailSelected
      product.descmax = parseInt(descmax) || 0
      product.detaildisplay = detaildisplay   
      product.detailId = id
      product.saleprice = saleprice
      product.serviciosihot = servicesihot || null
      product.discountApplied = 0
      product.itinerario = {}
      product.blackoutDates = { minDate: 'today', disable:[] , maxDate: ''}
      //mas deletes
      delete product.category
    // delete product.coverImage
      delete product.detail
      delete product.currencyCode
      delete product.type
      delete product.gallery
      delete product.videolink
      delete product.weblink
      delete product.productinfo
      delete product.terms
      delete product.servicesihot
      delete product.currency
      delete product.hotel
      delete product.metatag
      delete product.location
      delete product.startDate
      delete product.startTime
      delete product.endDate
      delete product.endDateFormat
      delete product.endTimeFormat
      delete product.hotelName
      delete product.locationName
      delete product.startDateFormat
      delete product.startTimeFormat
      
      //AÑADO PROPIEDADES ADICIONALES
      product.uuid = stringAleatorio() //les genero key unicos aleatorios
      product.salepriceWithDiscount = saleprice
      product.originalSalePrice = saleprice
      product.qty = qty || 1
      product.cost = product.cost || '0.00'
      product.operationdate = null
      product.operationtime = '12:00'
      product.note = ''
      product.clientnote = '',
      product.requiredFieldsFilled = false
      product.orderdetailbeo = { titulo: '', backup: null, locacion: null, comentario: '', pax: 0, numreserva: '', detailbeo: [] },

      //REMUEVO detailSelected
      delete product.detailSelected
      return product
    },
    formatPhoneNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which      
      const respuesta = this.codesAllowed.includes(keyCode)
      if (!respuesta) {
        event.preventDefault()
      }
    },
    async qtyHasChanged(product){
      const{ uuid, qty, operationdate } = product
      const text = `Quantity has been increased: Check availability with the new quantity selected`
      showAlertMessage( 'Qty has changed', 'InfoIcon', text, 'warning', 3000, 'bottom-right' )
      await this.setConfigBlackoutDatesProduct(product, qty)
      if(operationdate){
        const payload = { uuid, date: null }
        this.setOperationDateProduct(payload)
      }
    },
    async setConfigBlackoutDatesProduct(item, qty){
      const { uuid, id } = item
      const dates = await this.fetchBlackoutDatesProduct({idP:id, qty, fini: currentDate()})
      dates.sort( (a,b) => { return new Date(a.date) - new Date(b.date) })   
      const blackoutDates = dates.map( d => d.date)
      // const maxDate = blackoutDates.length ? blackoutDates[blackoutDates.length - 1] : ''
      const configDate = { uuid, blackoutDates , maxDate: '' }
      this.setBlackoutsDateProduct(configDate)      
    },
    existsInCart( item ) {        
      const { detailId, categoryName, modelType }  = item   
      const prodInCart = this.cart.find( product => product.detailId === detailId && product.modelType === modelType )
      return ( prodInCart && categoryName != "Transfer" ) ? true : false      
    },
    handlerQtyProduct( product, detail, isBestSeller){
      const idProduct = product.id
      const idDetail = detail.id
      const payload = { idProduct, idDetail, qty: detail.qty, isBestSeller }
      this.setQtyProductDetail( payload )    
    },
    handlerQtyProductSingle(product, detail, isBestSeller){
      const idProduct = product.id
      const idDetail = detail.id
      const payload = { idProduct, idDetail, qty: detail.qty, isBestSeller}
      this.setQtySingleProductDetail( payload )    
    },
    initCarousel(){   
      //obtener la imagen
      const imagenHero = document.querySelector('.hero')
      //inicializar las variables
      let i = 0
      let tiempo = 0
      // arreglo de imagenes de fondo 
      const imagenes = [ require('@/assets/images/landing/arriba2.jpg'), require('@/assets/images/landing/arriba.jpg'), require('@/assets/images/landing/lake.jpg')]
      setInterval( ()=> {
        imagenHero.style.backgroundPositionY= '-'+tiempo+'px'
        if(tiempo > 80){
          tiempo = 0
          imagenHero.style.backgroundImage = "url("+imagenes[i]+")"
        if(i === imagenes.length - 1){
          i = 0
        } else {
          i++
        }
      }
        tiempo++
      },100)       
    },
  }
}

